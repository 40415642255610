export default {
  computed: {
    client () {
      return isNaN(parseInt(this.$route.params.client)) ? this.$route.params.clientSymbol : this.$route.params.client
    },
    clientObject () {
      return this.$store.state.base.user.serviceClients[this.service].find(client => parseInt(client.id) === (isNaN(parseInt(this.$route.params.client)) ? parseInt(this.$route.params.clientSymbol) : parseInt(this.$route.params.client)))
    }
  }
}
